import { createRouter, createWebHistory } from "vue-router";
import AuthRequired from '@/utils/AuthRequired'

const routes = [
	{
		path: '/',
		alias: '/page/app',
		component: () => import('./views/app'),
		redirect: '/page/app/dashboard',
		beforeEnter: AuthRequired,
		children: [
			{
				name: 'dashboard',
				path: '/page/app/dashboard',
				component: () => import(
					'./views/app/dashboard'
				),
				// beforeEnter: AuthRequired,

			},
			{
				name: 'dossiers',
				path: '/page/app/dossiers',
				component: () => import(
					'./views/app/dossiers'
				),
				// beforeEnter: AuthRequired,

			},
			{
				name: 'travel_files',
				path: '/page/app/travel_files',
				component: () => import(
					'./views/app/travel_files'
				),
				// beforeEnter: AuthRequired,

			},
			{
				name: 'invoices',
				path: '/page/app/invoices',
				component: () => import(
					'./views/app/invoices'
				),
				// beforeEnter: AuthRequired,

			},
			{
				name: 'settings',
				path: '/page/app/settings',
				component: () => import(
					'./views/app/settings'
				),
				// beforeEnter: AuthRequired,
			}, 
			{
				name: 'settings',
				path: '/page/app/settings/messages',
				component: () => import(
					'./views/app/settings_messages'
				),
				// beforeEnter: AuthRequired,
			}, 
			{
				name: 'settings',
				path: '/page/app/settings/airports',
				component: () => import(
					'./views/app/settings_airports'
				),
				// beforeEnter: AuthRequired,
			}, 
			{
				name: 'settings',
				path: '/page/app/settings/airlines',
				component: () => import(
					'./views/app/settings_airlines'
				),
				// beforeEnter: AuthRequired,
			}, 
			{
				name: 'settings',
				path: '/page/app/settings/crons',
				component: () => import(
					'./views/app/settings_crons'
				),
				// beforeEnter: AuthRequired,
			}, 
			{
				name: 'admin',
				path: '/page/app/administrator',
				component: () => import(/* webpackChunkName: "admin" */ './views/app/administrator'),
				// beforeEnter: AuthRequired,
			},
			{
				name: 'users',
				path: '/page/app/users/:action?/:id?',
				component: () => import(/* webpackChunkName: "admin" */ './views/app/users')
			},
			{
				name: 'organizations',
				path: '/page/app/organizations',
				component: () => import(/* webpackChunkName: "admin" */ './views/app/organizations')
			},
		]
	},
	{
		name: 'User',
		path: '/page/user',
		component: () => import(
			'./views/public/user'
		),
		children: [
			{ name: 'login', path: 'login/:message?', component: () => import('./views/public/user/Login')  },
			{ path: 'register', component: () => import('./views/public/user/Register') },
			{ path: 'forgot-password', component: () => import('./views/public/user/ForgotPassword') },
			{ path: 'register/:token', component: () => import('./views/public/user-register') }
		],

	},
	{
		name: 'Public',
		path: '/public',
		component: () => import('./views/public'),

	},
	{ path: '/page/login', redirect: '/page/user/login' },
	{ path: '/error', component: () => import(/* webpackChunkName: "error" */ './views/Error') },
	{ path: '/:pathMatch(.*)*', component: () => import(/* webpackChunkName: "error" */ './views/Error') },
]

const router = createRouter({
	linkActiveClass: 'active',
	routes,
	history: createWebHistory(),
});

export default router;
