<template>
	<div class="h-100">
		<router-view v-if="isLanguageLoaded"/>
	</div>
</template>
<script>
import { mapActions, mapMutations } from 'vuex'
import { getDirection } from '@/utils'
import { localeOptions, defaultLocale } from '@/constants/config'
import moment from 'moment';

export default {
	name: 'App',
	data () {
		return {
			user: false,
			localeOptions
		}
	},
	created () {
		localStorage.setItem('apiURL', process.env.VUE_APP_API_URL);

		// Execute overwriteCoreLocale function whenever app is loaded to set the back-end session language variable with localStorage variable
		let locale = localeOptions.filter(l => l.id == (localStorage.getItem('currentLanguage') || defaultLocale))[0];
		if(locale) this.overwriteCoreLocale(locale.id);
	},
	computed: {
		isLanguageLoaded: function() {
			return this.$i18n.availableLocales.length > 0;
		}
	},
	methods: {
		...mapActions(['logout']),
		overwriteCoreLocale: function(locale) {
            // Overwrite SBA Core locale for the back-end / e-mails send by the core
            this.req('GET', `/message/overwriteCoreLanguage/${locale}`)
                .then((response) => {
					if (!response.status) {
						//console.log for testing ios
						console.log(response);
						this.$swal({
							title: this.$t('corelanguage.error'),
							text: this.$t('corelanguage.description'),
							showCancelButton: false,
							reverseButtons: false,
							confirmButtonText: this.$t('corelanguage.confirm'),
							cancelButtonText: this.$t('corelanguage.label'),
							customClass: {
								cancelButton: 'btn-square',
								confirmButton: 'btn-square',
							},
						});                
					}
				}
			);
		}
	},
	beforeMount () {
		const direction = getDirection()
		if (direction.isRtl) {
			document.body.classList.add('rtl')
			document.dir = 'rtl'
			document.body.classList.remove('ltr')
		} else {
			document.body.classList.add('ltr')
			document.dir = 'ltr'
			document.body.classList.remove('rtl')
		}
	}
}
</script>
